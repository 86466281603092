<template>
  <div>
    <el-form
      :model="customer"
      label-position="left"
      label-width="100px"
      :rules="rules"
      ref="customerDetail"
    >
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item prop="name" label="Name">
            <el-input
              v-model="customer.name"
              clearable
              size="mini"
              :disabled="customer._id != null"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item prop="type" label="Type">
            <el-select
              v-model="customer.type"
              size="mini"
              clearable
              style="width: 100%;"
            >
              <el-option
                v-for="item in types"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item label="description">
            <el-input
              type="textarea"
              v-model="customer.description"
              size="mini"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item label="Area">
            <el-select
              v-model="customer.area"
              clearable
              style="width: 100%;"
              size="mini"
            >
              <el-option
                v-for="item in areas"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item label="Address">
            <el-input
              v-model="customer.addr"
              type="textarea"
              size="mini"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item label="Owner">
            <el-select
              v-model="customer.assignee"
              clearable
              style="width: 100%;"
              size="mini"
            >
              <el-option
                v-for="item in assignees"
                :key="item.username"
                :label="item.name"
                :value="item.username"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <div style="width: 100%; height: 30px; line-height: 30px; float:left;">
          <el-button
            type="primary"
            style="float: right;"
            size="mini"
            @click="handleConfirm"
            >Confirm</el-button
          >
          <el-button
            style="float:right; margin-right: 10px;"
            size="mini"
            @click="handleCancel"
            >Cancel</el-button
          >
        </div>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import config from "../utils/config";
import http from "../utils/http";
export default {
  name: "CustomerDetail",
  props: {
    editCustomer: {},
  },
  data() {
    return {
      rules: {
        name: [
          {
            required: true,
            message: "customer name is required!",
            trigger: "blur",
          },
          {
            min: 3,
            max: 50,
            message: "Name length should contain 3-40 letters.",
            trigger: "blur",
          },
        ],
      },
      assignees: [],
      customer: {
        name: "",
        type: "",
        area: "",
        description: "",
        addr: "",
        assignee: "",
      },
      types: config.customerTypes,
      areas: config.areas,
    };
  },
  mounted() {
    this.getInternalUsers();
    if (this.editCustomer != null) {
      this.customer._id = this.editCustomer._id;
      this.customer.name = this.editCustomer.name;
      this.customer.addr = this.editCustomer.addr;
      this.customer.description = this.editCustomer.description;
      this.customer.assignee = this.editCustomer.assignee;
      this.customer.area = this.editCustomer.area;
      this.customer.type = this.editCustomer.type;
    }
  },
  methods: {
    saveCustomer() {
      http.put("/customer/updateCustomer", this.customer).then((res) => {
        if (res.data.status === 1) {
          this.$message({
            message: "Customer was saved successfully",
            type: "success",
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleConfirm() {
      if (this.customer._id != null) {
        this.saveCustomer();
        this.$emit("confirm");
        return;
      }
      this.$refs.customerDetail.validate((valid) => {
        if (valid) {
          http.post("/customer/addCustomer", this.customer).then((res) => {
            if (res.data.status == 1) {
              this.$message({
                type: "success",
                message: "The customer was created successfully.",
              });
              this.$emit("confirm");
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    getInternalUsers() {
      http.get("/user/internal").then((res) => {
        if (res.data.status === 1) {
          this.assignees = res.data.data;
        } else {
          this.$message.error("Error happened!");
        }
      });
    },
  },
};
</script>
<style scoped></style>
