<template>
  <div>
    <div class="header">
      <el-button size="mini" type="primary" @click="handleCreateClick"
        >Create</el-button
      >
      <el-input
        size="mini"
        style="width: 200px; margin-left: 20px; "
        suffix-icon="el-icon-search"
        clearable
        placeholder="search..."
        v-model="kw"
      ></el-input>
      <el-select
        v-model="customerType"
        placeholder="Customer Type"
        size="mini"
        clearable
        style="margin-left: 20px"
      >
        <el-option
          v-for="item in customerTypes"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
      <el-select
        v-model="assignee"
        multiple
        placeholder="Owner"
        clearable
        size="mini"
        style="margin-left: 20px;"
        collapse-tags
      >
        <el-option
          v-for="item in assignees"
          :key="item.username"
          :label="item.name"
          :value="item.username"
        ></el-option>
      </el-select>
      <el-button
        size="mini"
        style="margin-left: 20px;"
        type="primary"
        icon="el-icon-lightning-full"
        @click="getCustomer"
      ></el-button>
      <el-tag size="medium" type="info" style="margin-left: 20px;"
        >In total: {{ selectedCustomers.length }}</el-tag
      >
    </div>
    <el-table
      :data="selectedCustomers"
      :height="tableHeight"
      stripe
      border
      size="mini"
      style="margin-top: 10px; float:left;"
    >
      <el-table-column label="Name" prop="name"></el-table-column>
      <el-table-column label="Type" prop="type"></el-table-column>
      <el-table-column label="Description" prop="description"></el-table-column>
      <el-table-column label="Area" prop="area"></el-table-column>
      <el-table-column label="Address" prop="addr"></el-table-column>
      <el-table-column label="Owner" prop="assignee"></el-table-column>
      <el-table-column label="Action" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            plain
            @click="handleEdit(scope)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            circle
            plain
            @click="deleteCustomer(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" :visible.sync="showDialog">
      <customer-detail
        v-if="showDialog"
        @confirm="handleConfirm"
        @cancel="handleCancel"
        :editCustomer="customer"
      ></customer-detail>
    </el-dialog>
  </div>
</template>
<script>
import config from "../utils/config";
import http from "../utils/http";
import CustomerDetail from "../components/CustomerDetail.vue";
export default {
  name: "Customer",
  components: {
    CustomerDetail,
  },
  data() {
    return {
      title: "Create Customer",
      showDialog: false,
      customerTypes: config.customerTypes,
      customerType: "",
      areas: config.areas,
      area: [],
      assignees: [],
      assignee: [],
      kw: "",
      tableHeight: window.innerHeight - 180,
      customers: [
        {
          name: "Geely",
          type: "existing",
          description: "Geely ltd.",
          area: "Ningbo",
          contacts: [],
          addr: "",
          assignee: "",
        },
      ],
      customer: {},
    };
  },
  mounted() {
    this.getCustomer();
    this.getInternalUsers();
  },
  computed: {
    selectedCustomers() {
      var kw = this.kw.toLowerCase();
      return this.customers.filter((value) => {
        return (
          (value.name.toLowerCase().indexOf(kw) > -1 ||
            value.description.toLowerCase().indexOf(kw) > -1 ||
            value.addr.toLowerCase().indexOf(kw) > -1 ||
            value.area.toLowerCase().indexOf(kw) > -1) &&
          (this.customerType == "" ? true : value.type == this.customerType) &&
          (this.assignee.length > 0
            ? this.assignee.indexOf(value.assignee) > -1
            : true)
        );
      });
    },
  },
  methods: {
    handleEdit(scope) {
      this.title = "Edit Customer";
      this.customer = scope.row;
      this.showDialog = true;
    },
    handleCancel() {
      this.showDialog = false;
    },
    handleConfirm() {
      this.showDialog = false;
      this.getCustomer();
    },
    handleCreateClick() {
      this.title = "Create Customer";
      this.customer = {};
      this.showDialog = true;
    },
    getInternalUsers() {
      http.get("/user/internal").then((res) => {
        if (res.data.status === 1) {
          this.assignees = res.data.data;
        } else {
          this.$message.error("Error happened!");
        }
      });
    },
    getCustomer() {
      http.get("/customer/getCustomer").then((res) => {
        if (res.data.status === 1) {
          this.customers.splice(0, this.customers.length);
          this.customers = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    deleteCustomer(scope) {
      http
        .post("/customer/deleteCustomer", { name: scope.row.name })
        .then((res) => {
          if (res.data.status === 1) {
            this.$message({
              message: "customer was deleted successfully!",
              type: "success",
            });
            this.customers.splice(this.customers.indexOf(scope.row), 1);
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
  },
};
</script>
<style scoped>
.header {
  width: 1000px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  float: left;
  /* margin-top: -10px; */
}
</style>
